import Transformer from './Transformer'
import QualificationTransformer from './QualificationTransformer'
import ReponseTransformer from './ReponseTransformer'
import HorseTransformer from './HorseTransformer'
import TierTransformer from './TierTransformer'

export default class QualificationLinkTransformer extends Transformer {

	table = 'qualification_link'

	getResponses(item, formatted=false) {
        return this.db().t('reponse')
        .then(table => {
            return table.where({
                reponse_fk: parseInt(item.qualificationlink_id),
                reponse_type: 'qualification_link'
            })
		})
		.then(col => {
			return col.transform(new ReponseTransformer('withQuestion'))
		})
        .then(async reponses => {
            if(formatted) {
				reponses = reponses.filter(rep => {
                    return rep.reponse_data[0] !== null
				})

				const all_reponses = []
				reponses.forEach(rep => {
					all_reponses.push({
                        question_id: rep.reponse_question,
                        reponse_data: rep.reponse_data
                    })
				})

				const formatted_reponses = reponses
				.map((rep) => {
					return rep.formatted_data
				})
				.join(', ')

				return {
					reponse_formatted_data: formatted_reponses,
					reponse_data: all_reponses
				}
			}

            return reponses
        })
	}

    async getEntity(item) {
        if(item.qualificationlink_type == 'horse') {
            return this.db().t('horse')
            .then(table => {
                return table.where({ horse_id: parseInt(item.qualificationlink_fk) })
            })
            .then(col => {
                return col.transform(new HorseTransformer('heavy'))
            }).then(horses => {
                horses[0].entity_id = horses[0].horse_id
                return horses[0]
            })
        }
        else if(item.qualificationlink_type == 'tiers') {
            return this.db().t('tiers')
            .then(table => {
                return table.where({ tiers_id: parseInt(item.qualificationlink_fk) })
            })
            .then(col => {
                return col.transform(new TierTransformer('light'))
            }).then(tiers => {
                tiers[0].entity_id = tiers[0].tiers_id
                return tiers[0]
            })
        }
    }

	async fetch (col) {
		return col.with({
			'qualification' : 'qualificationlink_qualification'
        })
	}

    async transform (link) {
        return {
			qualificationlink_id			: link.qualificationlink_id,
			qualificationlink_qualification	: await QualificationTransformer.process(link.qualification),
			qualificationlink_type			: link.qualificationlink_type,
			qualificationlink_fk			: link.qualificationlink_fk,
        }
    }

    async transformLight (link) {
        return {
            qualificationlink_id            : link.qualificationlink_id,
            qualificationlink_qualification : link.qualificationlink_qualification,
            qualificationlink_type          : link.qualificationlink_type,
            qualificationlink_fk            : link.qualificationlink_fk,
        }
    }

    async fetchWithResponses (col) {
		return col.with({
			'qualification' : 'qualificationlink_qualification'
        })
	}

    async transformWithResponses (link) {
		const reponses = await this.getResponses(link, true)
        const qualification = await QualificationTransformer.process(link.qualification)

    	return {
			qualificationlink_id			: link.qualificationlink_id,
			qualificationlink_qualification	: qualification,
			qualificationlink_type			: link.qualificationlink_type,
			qualificationlink_fk			: link.qualificationlink_fk,
			qualification_libelle			: qualification.qualification_libelle,
			qualification_reponse			: reponses.reponse_formatted_data,
            qualification_reponse_data      : reponses.reponse_data
        }
    }

     async fetchWithResponsesAndEntityFk (col) {
        return col.with({
            'qualification' : 'qualificationlink_qualification',
        })
    }

    async transformWithResponsesAndEntityFk (link) {
        const reponses = await this.getResponses(link, true)
        const qualification = await QualificationTransformer.process(link.qualification)
        const entity = await this.getEntity(link)

        return {
            qualificationlink_id            : link.qualificationlink_id,
            qualificationlink_qualification : qualification,
            qualificationlink_type          : link.qualificationlink_type,
            qualificationlink_fk            : link.qualificationlink_fk,
            qualification_libelle           : qualification.qualification_libelle,
            qualification_reponse           : reponses.reponse_formatted_data,
            qualification_reponse_data      : reponses.reponse_data,
            qualification_fk_entity         : entity
        }
    }
}
