import QualificationTypeTransformer from '@/assets/js/dexie/transformers/QualificationTypeTransformer'
import QualificationTransformer from '@/assets/js/dexie/transformers/QualificationTransformer'
import QualificationLinkTransformer from '@/assets/js/dexie/transformers/QualificationLinkTransformer'
import QuestionTransformer from '@/assets/js/dexie/transformers/QuestionTransformer'
import HorseTransformer from '@/assets/js/dexie/transformers/HorseTransformer'
import TierTransformer from '@/assets/js/dexie/transformers/TierTransformer'
import Common from '@/assets/js/common.js'
import Constants from '../../config.local.js'

export default {
	methods: {
		getQualificationTypes() {
			return this.$storage.db.t('qualification_type')
			.then(table => {
				return table.toCollection()
			})
			.then(col => {
				return QualificationTypeTransformer.process(col)
			})
		},

		getQualificationType(type_libelle) {
			return this.$storage.db.t('qualification_type')
			.then(table => {
				return table.where({
					qualificationtype_libelle: type_libelle
				})
			})
			.then(col => {
				return col.first()
			})
			.then(type => {
				return QualificationTypeTransformer.process(type)
			})
		},

		getQualifications(type_id, withoutChildren = false) {
			return this.$storage.db.t('qualification')
			.then(table => {
				return table.where({
					qualification_type: parseInt(type_id),
					qualification_parent: 0
				})
			})
			.then(col => {
				if(withoutChildren) {
					return col.transform(new QualificationTransformer('withoutChildren'))
				}
				return col.transform(new QualificationTransformer())
			})
			.then(arr => {
				if(withoutChildren) {
					return arr
				}
				return this.flattenQualifications(arr)
			})
		},

		getUserQualifications(type_id) {
			return this.$storage.db.t('qualification')
			.then(table => {
				return table.where({
					qualification_type: parseInt(type_id),
					qualification_parent: 0
				})
			})
			.then(col => {
				return col.filter(q => q.qualification_licencekey != null && q.qualification_licencekey != '')
			})
			.then(col => {
				return col.transform(new QualificationTransformer())
			})
			.then(arr => {
				return this.flattenQualifications(arr)
			})
		},

		async getFicheQualifications(type_libelle, qualification_fk) {
			const qualif_type = await this.getQualificationType(type_libelle)
			const qualifs = await this.getQualifications(qualif_type.qualificationtype_id)

			let qualif_res = []

			await Common.asyncForEach(
				qualifs,
				async (qualif) => {
					const qualif_link = await this.getQualificationLink(qualif.qualification_id, type_libelle, qualification_fk)

					if(qualif_link) {
						let qualif_to_add = qualif
						qualif_to_add.qualification_reponse = qualif_link.qualification_reponse
						qualif_res.push(qualif_to_add)
					}
				}
			)
			
			return qualif_res
		},

		getQualificationLink(qualificationlink_qualification, qualificationlink_type, qualificationlink_fk) {
			return this.$storage.db.t('qualification_link')
			.then(table => {
				return table.where({
					qualificationlink_qualification: parseInt(qualificationlink_qualification),
					qualificationlink_type: qualificationlink_type,
					qualificationlink_fk: parseInt(qualificationlink_fk)
				})
			})
			.then(col => {
				return col.first()
			})
			.then(link => {
				return QualificationLinkTransformer.process(link, 'withResponses')
			})
		},

		getQualificationLinkByEntity(qualificationlink_type, qualificationlink_fk) {
			return this.$storage.db.t('qualification_link')
			.then(table => {
				return table.where({
					qualificationlink_type: qualificationlink_type,
					qualificationlink_fk: parseInt(qualificationlink_fk)
				})
			})
			.then(col => {
				return col.transform(new QualificationLinkTransformer('light'))
			})
		},
	
		flattenQualifications(qualifications, indent=0) {
			let flatten = []
	
			qualifications.forEach(qualif => {
				qualif.indent = indent
				flatten.push(qualif)
				flatten = flatten.concat(
					this.flattenQualifications(qualif.children, indent + 1)
				)
			})
			return flatten
		},

		flattenQualificationsName(qualifications, indent=0) {
			let flatten = []

			qualifications.forEach(qualif => {
				qualif.indent = indent
				flatten.push(qualif)
				flatten = flatten.concat(
					this.flattenQualificationsName(qualif.children, indent + 1)
				)
			})

			return flatten
		},

		getQualificationById(qualif_id) {
			return this.$storage.db.t('qualification')
			.then(table => {
				return table.where({
					qualification_id: parseInt(qualif_id),
				})
			})
			.then(col => {
				return col.transform(new QualificationTransformer())
			})
		},

		getQuestionQualification(qualif_id) {
			return this.$storage.db.t('question')
			.then(table => {
				return table.where({
					question_fk: parseInt(qualif_id),
					question_type: 'qualification',
				})
			})
			.then(col => {
				return col.transform(new QuestionTransformer())
			})
		},

		createQualification(type, parent, libelle) {
			const qualif_id = Common.getNegativeId()

			return this.$storage.db.t('qualification')
                .then(table => {
                    return table.add({
                    	qualification_id: qualif_id,
                        qualification_type: type,
                        qualification_parent: parent,
                        qualification_libelle: libelle,
                        qualification_licencekey: Constants.USER_LICENCE_KEY,
                        qualification_valide: 1
                    })
                })
                .then(col => {
					return qualif_id
				})
		},

		updateQualification(qualif_id, parent, libelle) {
			return this.$storage.db.t('qualification')
                .then(table => {
                    return table.update(parseInt(qualif_id), {
                        qualification_parent: parent,
                        qualification_libelle: libelle,
                    })
                })
		},

		async saveQualificationQuestions(qualif_id, questions) {
			await Promise.all(questions.map(async (quest) => {
				let param = {
					question_fk: qualif_id,
					question_type: "qualification",
					question_libelle: quest.question_libelle,
					question_index: quest.question_index,
					question_questiontype: quest.question_questiontype,
					question_placeholder: quest.question_placeholder,
					question_questiondata: JSON.parse(quest.question_questiondata),
					question_licencekey: Constants.USER_LICENCE_KEY
				}
				if(quest.question_id) {
					return this.$storage.db.t('question')
	                .then(table => {
	                    return table.update(parseInt(quest.question_id), param)
	                })
				}
			 	else {
			 		param.question_id = Common.getNegativeId()
			 		return this.$storage.db.t('question')
	                .then(table => {
	                    return table.add(param)
	                })
			 	}
			}))
		},

		prepareQuestionsTab(questions) {
            const question_filled = this.deppCloneObj(questions)

            question_filled.forEach(question => {
                question.question_questiondata = JSON.stringify(question.question_questiondata)
            })

            return question_filled
        },

        async deleteQualif(qualif_id) {
        	await this.$storage.db.t('qualification')
			    .then(table => {
			    	return table.where('qualification_id').equals(parseInt(qualif_id)).invalid()
			    })

			await this.$storage.db.t('qualification_link')
			    .then(table => {
			    	return table.where('qualificationlink_qualification').equals(parseInt(qualif_id)).invalid()
			    })

			let children = await this.$storage.db.t('qualification')
			.then(table => {
				return table.where({
					qualification_parent: parseInt(qualif_id),
				})
			})
			.then(col => {
				return col.transform(new QualificationTransformer('withoutChildren'))
			})

			if(children.length === 0) return true

			children.forEach(async child => {
				await this.deleteQualif(child.qualification_id)
			})
        },

        async saveQualificationFiche(qualif_id, fk_id, type, reponses, link_id = 0) {
			let qualif_link = link_id
	        return this.$storage.db.transaction(
	            'rw',
	            ['qualification_link', 'reponse'],
	            async () => {
	            	if(qualif_link === 0) {
	            		qualif_link = Common.getNegativeId()

						await this.$storage.db.t('qualification_link')
			                .then(table => {
			                    return table.add({
			                    	qualificationlink_id: qualif_link,
			                        qualificationlink_type: type,
			                        qualificationlink_fk: parseInt(fk_id),
			                        qualificationlink_qualification: qualif_id,
			                        qualificationlink_valide: 1
			                    })
			                })
	            	}
	            	else {
		                await this.$storage.db.t('qualification_link')
		                .then(table => {
		                    return table
		                    .where('qualificationlink_id')
		                    .anyOf(parseInt(qualif_link))
		                    .modify({
		                        qualificationlink_type: type,
		                        qualificationlink_fk: parseInt(fk_id),
		                        qualificationlink_qualification: qualif_id,
		                        qualificationlink_valide: 1
		                    })
		                })
	            	}


	            	await this.$storage.db.t('reponse')
			        .then(table => {
			            return table.where({
			                reponse_fk: parseInt(qualif_link),
			                reponse_type: 'qualification_link'
			            }).invalid()
			        })

			        if (reponses) {
			            await Common.asyncForEach(reponses, async (reponse) => {
			                await this.$storage.db.t('reponse')
			                .then(table => {
			                    return table.add({
			                        reponse_id: Common.getNegativeId(),
			                        reponse_question: reponse.reponse_question,
			                        reponse_fk: parseInt(qualif_link),
			                        reponse_type: 'qualification_link',
			                        reponse_valide: 1,
			                        reponse_data: reponse.reponse_data,
			                        reponse_data_hidden: reponse.reponse_data_hidden
			                    })
			                })
			            })
			        }
	            }
	        )
	    },

	    async deleteQualifToFiche(qualification_id, qualificationlink_fk, qualificationlink_type) {
	    	return this.$storage.db.transaction(
	            'rw',
	            ['qualification_link', 'reponse'],
	            async () => {
	            	const qualif_link = await this.$storage.db.t('qualification_link')
						.then(table => {
							return table.where({
								qualificationlink_qualification: parseInt(qualification_id),
								qualificationlink_type: qualificationlink_type,
								qualificationlink_fk: parseInt(qualificationlink_fk)
							})
						})
						.then(col => {
							return col.first()
						})

					await this.$storage.db.t('qualification_link')
					    .then(table => {
					    	return table.where('qualificationlink_id').equals(parseInt(qualif_link.qualificationlink_id)).invalid()
					    })	


					await this.$storage.db.t('reponse')
					    .then(table => {
					    	return table
					    		.where({
					    			reponse_fk: qualif_link.qualificationlink_id,
					    			reponse_type: 'qualification_link'
					    		})
					    		.invalid()
					    })
	            }
	        )
	    },

	    async searchQualificationAPI(search, type) {
            const url = this.constructRoute(Constants.SEARCH_QUALIFICATION, {type}) + "?licence_key="+Constants.USER_LICENCE_KEY
            const res = await this.$request.request_post_api("QualificationMixin::searchQualificationAPI", url, {search: search}, false)
				.then(async (res) => {
					return res
				})
				.catch(e => {
					console.error("QualificationMixin::searchQualificationAPI", e)
					return null
				})
            return res
	    },

	    async searchQualification(search, type) {
	    	let entities = []

	    	if(type === "horse") {
	    		entities = await this.$storage.db.t('horse')
			    .then(table => {
			    	return table
			    })	
				.then(col => {
					return HorseTransformer.process(col, 'light')
				})
	    	} else if(type === "tiers") {
	    		entities = await this.$storage.db.t('tiers')
			    .then(table => {
			    	return table
			    })	
				.then(col => {
					return TierTransformer.process(col, 'withPhone')
				})
	    	}

	    	// on enlève les conditions vides dans la recherche, cad des questions sans réponse sur une qualif
	    	let search_local = search
	    	search_local.forEach(critere => {
	    		critere.qualification_reponse = critere.qualification_reponse.filter(rep => rep.reponse_data[0] !== null && rep.reponse_data[0] !== undefined)
	    	})

	    	let found_entities = []

	    	await Common.asyncForEach(
				entities,
				async (entity) => {
					if(type === "horse") {
						entity.entity_id = entity.horse_id
					} else if(type === "tiers") {
						entity.entity_id = entity.tiers_id
					}

					const qualif_link = await this.$storage.db.t('qualification_link')
				    .then(table => {
				    	return table.where({
				    		qualificationlink_type: type,
							qualificationlink_fk: parseInt(entity.entity_id)
				    	})
				    })	
					.then(col => {
						return QualificationLinkTransformer.process(col, 'withResponses')
					})

					let critere_valide = 0
					let critere_exclu = 0

					search_local.forEach(critere => {
						let reponse_valide = 0
						if(critere.qualification_reponse.length == 0) {
							qualif_link.forEach(link => {
								if(link.qualificationlink_qualification.qualification_id == critere.qualification_id) {
									reponse_valide++;
								}
							})
						}
						critere.qualification_reponse.forEach(reponse => {
							qualif_link.forEach(link => {
								link.qualification_reponse_data.forEach(question => {
									if(link.qualificationlink_qualification.qualification_id == critere.qualification_id && question.question_id == reponse.reponse_question 
										&& (reponse.reponse_data[0] == null || reponse.reponse_data[0] == undefined || question.reponse_data.some(r => reponse.reponse_data.indexOf(r) >= 0))) {
										reponse_valide++;
									}
								})
							})
						})

						// les trois else if font la même chose mais je préfère séparer les conditions
						if(critere.qualification_reponse.length != 0 && critere.qualification_reponse.length <= reponse_valide && critere.type == "pas_egale") {
							critere_exclu++
						} else if(critere.qualification_reponse.length != 0 && critere.qualification_reponse.length <= reponse_valide && critere.type == "egale") {
							critere_valide++
						} else if(critere.qualification_reponse.length >= reponse_valide && critere.type == "pas_egale") {
							critere_valide++
						} else if(critere.qualification_reponse.length < reponse_valide && critere.type == "egale") {
							critere_valide++
						}
					})

					if(critere_valide == search.length && critere_exclu == 0) {
						found_entities.push(entity)
					} 
				})

	    	let res = []
	    	found_entities.forEach(async entity => {
	    		const qualif_link = await this.$storage.db.t('qualification_link')
				    .then(table => {
				    	return table.where({
				    		qualificationlink_type: type,
							qualificationlink_fk: parseInt(entity.entity_id)
				    	})
				    })	
					.then(col => {
						return QualificationLinkTransformer.process(col, 'withResponses')
					})
				let qualif = qualif_link.map(qualif => qualif.qualification_libelle)

    			entity.qualification_libelle = qualif.join(', ')

    			res.push(entity)
	    	})

	    	return res
	    },

	    async saveMultipleQualication(ids, qualif_to_add, qualif_to_delete, type) {
	    	await Common.asyncForEach(ids, async (id) => {
	    		// vérifie si une qualif existe déjà sur une entité, sinon l'ajoute
	    		await Common.asyncForEach(qualif_to_add, async (qualif) => {
	    			const exist = await this.$storage.db.t('qualification_link')
						.then(table => {
							return table.where({
		                        qualificationlink_type: type,
								qualificationlink_qualification: parseInt(qualif.qualification_id),
								qualificationlink_fk: parseInt(id)
							})
						})
						.then(col => {
							return col.first()
						})

					if(!exist && (!qualif.ids || qualif.ids && qualif.ids.includes(id))) {
		    			await this.$storage.db.t('qualification_link')
		                .then(table => {
		                    return table.add({
		                    	qualificationlink_id: Common.getNegativeId(),
		                        qualificationlink_type: type,
		                        qualificationlink_fk: parseInt(id),
		                        qualificationlink_qualification: parseInt(qualif.qualification_id),
		                        qualificationlink_valide: 1
		                    })
		                })
					}

	    		})

	    		// vérifie si une qualif existe sur une entité et supprime la qualif + les réponses
	    		await Common.asyncForEach(qualif_to_delete, async (qualif) => {
	    			const exist = await this.$storage.db.t('qualification_link')
						.then(table => {
							return table.where({
								qualificationlink_qualification: parseInt(qualif),
								qualificationlink_fk: parseInt(id)
							})
						})
						.then(col => {
							return col.first()
						})

					if(exist) {
						await this.$storage.db.t('qualification_link')
					    .then(table => {
					    	return table.where('qualificationlink_id').equals(parseInt(exist.qualificationlink_id)).invalid()
					    })	

						await this.$storage.db.t('reponse')
					    .then(table => {
					    	return table
					    		.where({
					    			reponse_fk: exist.qualificationlink_id,
					    			reponse_type: 'qualification_link'
					    		})
					    		.invalid()
					    })
					}
	    		})
	    	})
	    }
	}
}
