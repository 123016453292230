import Transformer from './Transformer'


export default class QualificationTypeTransformer extends Transformer {

	table = 'qualification_type'

    async transform (type) {
        return {
			qualificationtype_id		: type.qualificationtype_id,
			qualificationtype_libelle	: type.qualificationtype_libelle
        }
    }
}
