import Vue from 'vue'
import Transformer from './Transformer'


export default class QualificationTransformer extends Transformer {

	table = 'qualification'

	getQuestions(item) {
		return this.db().t('question')
        .then(table => {
            return table.where({
                question_fk: parseInt(item.qualification_id),
                question_type: 'qualification'
            }).toArray()
        })
        .then(async result => {
        	return result.map(question => question.question_libelle).join(', ')
        })
	}

	async children (qualif) {
		return this.db().t('qualification')
		.then(table => {
			return table.where({
				qualification_parent: qualif.qualification_id
			})
		})
		.then(col => {
			return col.transform(new QualificationTransformer())
		})
	}

	async fetch (col) {
		this.additionalColumns({
			children: this.children
		})

		return col
	}

    async transform (qualif) {
    	const questions = await this.getQuestions(qualif)

        return {
			qualification_id		: qualif.qualification_id,
			qualification_libelle	: qualif.qualification_licencekey ? qualif.qualification_libelle : Vue.i18n.translate('qualification.qualification_libelles.' + qualif.qualification_libelle.toLowerCase()),
			qualification_parent	: qualif.qualification_parent,
			children				: qualif.children,
			questions 				: questions
        }
    }

    async transformWithoutChildren (qualif) {
    	return {
    		qualification_id		: qualif.qualification_id,
			qualification_libelle	: qualif.qualification_licencekey ? qualif.qualification_libelle : Vue.i18n.translate('qualification.qualification_libelles.' + qualif.qualification_libelle.toLowerCase()),
			qualification_parent	: qualif.qualification_parent,
    	}
    }
}
